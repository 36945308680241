import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import {
  map,
  Observable,
  of,
  retry,
  switchMap,
  tap,
} from 'rxjs';
import { GraphService } from '../../../graphql/services/graph.service';
import {
  FilterInput,
  FiltersGraphqlInput,
  ListingGraphqlInput,
  PaginationGraphqlInput,
} from '../../../graphql/models';
import { LocalStorageService } from '../../../tools/services/local-storage.service';
import { NotificationRequestInterface } from '../../interfaces/notification-request.interface';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {


  constructor(
    private graphService: GraphService,
    private localStorageService: LocalStorageService,
    private afMessaging: AngularFireMessaging
  ) {}


  /**
   * Request permission from the user to show notifications using the Firebase Cloud Messaging (FCM) service.
   *
   * @returns An observable that emits the permission status of the request, which can be either `'granted'`, `'denied'`
   */
  requestPermission(): Observable<NotificationPermission> {
    return this.afMessaging.requestPermission;
  }

  /**
   * Retrieve the Firebase Cloud Messaging (FCM) token for the device.
   *
   * The function starts by requesting permission from the user to show notifications.
   * Then, it listens to changes in the FCM token using `afMessaging.tokenChanges`.
   * If a valid token is received, it is stored in local storage using the `localStorage.setValue` method.
   *
   * @returns An observable that emits the FCM token for the device, or `null` if the user denies permission to show notifications.
   */
  getToken() {
    return this.requestPermission().pipe(
      switchMap(() => {
        return this.afMessaging.tokenChanges
      }),
      tap((token: string | null) => {
        if (token) { this.localStorageService.set('notification_token', token); }
      }),
      // if cached is clear and after login successfully, always get error after permission is granted. so retry 1 solved the issue:
      // TODO: Refactor this.
      retry(1)
    );
  }

  /**
   * Add the Firebase Cloud Messaging (FCM) token for the device to the server.
   *
   * The function starts by checking if the token is already stored in local storage.
   * If it is, the token is used directly. If it's not, the `getToken` function is called to retrieve the token.
   * Then, the `graph.constructMutation` method is called to send the token to the server.
   * If the token is `null`, the function returns an observable that emits `null`.
   *
   * @returns An observable that emits the result of the server request, or `null` if the token is `null`.
   */
  addFirebaseToken() {
    return of(this.localStorageService.get<string>('notification_token')).pipe(
      switchMap((token: string | null) =>
        token ? of(token) : this.getToken()
      ),
      switchMap((token: string | null) =>
        token
          ? this.graphService.constructMutation<any>(
            'addFirebaseToken',
            { firebaseToken: 'CreateFirebaseTokenInput' },
            { firebaseToken: { token, device: navigator.userAgent } },
            ['code', 'text']
          )
          : of(null)
      )
    );
  }

  /**
   * Returns`afMessaging.messages` observable to subscribe to FCM notifications.
   */
  get messages() {
    return this.afMessaging.messages
  }


  /**
   * Marks a notification as seen.
   * @param id
   */
  markNotificationAsSeen(): Observable<any> {
    return this.graphService.constructMutation<{ markNotificationAsSeen: { code: number; text: string } }>(
        'markNotificationAsSeen',
        {},
        {},
        ['code', 'text']
      )
  }


  /**
   * Marks a notification as clicked.
   * @param id
   */
  markNotificationAsClicked(id: string): Observable<any> {
    return this.graphService.constructMutation<{ markNotificationAsSeen: { code: number; text: string } }>(
        'markNotificationAsClicked',
        { id: 'ID' },
        { id },
        ['code', 'text']
      )
  }

   /**
   * Delete a notification.
   * @param id
   */
   deleteNotification(id: string): Observable<any> {
    return this.graphService.constructMutation<{ DeleteNotification: { code: number; text: string } }>(
        'DeleteNotification',
        { id: 'ID' },
        { id },
        ['code', 'text']
      )
  }

  /**
   * Retrieve a list of notifications from the API.
   *
   * @param page The page number to retrieve.
   * @param rpp The number of notifications to retrieve per page (defaults to 7).
   * @returns An observable that emits the list of notifications.
   *
   * The function uses the `graph.constructListingQuery` method to send a GraphQL query to the API
   * to retrieve a paginated list of notifications.
   * The response from the API is mapped to extract the list of notifications and
   * update the local `notifications$` subject and the `isLastPage$` subject.
   */
  getNotifications(
    page: number,
    rpp: number = 7,
    filters:FilterInput[]|undefined=undefined
  ): Observable<{
   notifications: NotificationRequestInterface[], total: number, new: number, notClickedCount: number
}> {
    return this.graphService
      .constructQuery<{notifications: {notifications: NotificationRequestInterface[], total: number, new: number,notClickedCount:number}}>(
        [
          'notifications.id',
          'notifications.title',
          'notifications.text',
          'notifications.type',
          'notifications.data',
          'notifications.nid',
          'notifications.key',
          'notifications.created_at',
          'notifications.seen_at',
          'notifications.clicked_at',
          'total',
          'new',
          'notClickedCount'
        ],
        'notifications',
        this.getListingGraphqlInput(new PaginationGraphqlInput(page, rpp),new FiltersGraphqlInput(filters))
      )
      .pipe(map((response) => response.data.notifications),);
  }

  /**

   This function returns a ListingGraphqlInputInterface that represents the input for a listing query.
   @param pagination The pagination information to include in the input.
   @returns The ListingGraphqlInputInterface for the listing query.
   */
  private getListingGraphqlInput(pagination: PaginationGraphqlInput,filters:FiltersGraphqlInput|null=null) {
    return ListingGraphqlInput.wrap(filters, pagination);
  }
}
